import React from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";
import { FormProvider } from "../contexts/FormContext";
import { Home } from "../pages/Home";
import { Legal } from "../pages/Legal";
import { Error } from "../pages/Error";

const path = `${process.env.PUBLIC_URL}`;

export const AppRouter = () => {
  return (
    <FormProvider>
    <Router>
      <Routes>
        <Route exact path={`${path}/`} element={<Home />} />
        <Route path={`${path}/legal`} element={<Legal />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </Router>
  </FormProvider>
  );
};


