import { useState } from "react";
import { useFormContext } from "../contexts/FormContext";
import { Step1 } from "../components/organisms/Steps/Step1";
import { Step2 } from "../components/organisms/Steps/Step2";
import { Step3 } from "../components/organisms/Steps/Step3";
import { Step4 } from "../components/organisms/Steps/Step4";
import { Step5 } from "../components/organisms/Steps/Step5";
import { Step6 } from "../components/organisms/Steps/Step6";
import { Footer } from "../components/organisms/Footer/footer";
import { helpHttp } from "services/helpHttp";
import { useValidations } from "../services/validations";
import { securanceMilenium, securancePremium, pricePremiumExtra, months } from "../data/constants";
import "../components/organisms/Home/home.scss";
//import locations from "data/cities.json";

export const Home = () => {
  const { formData, setFormData, totalPrice, setTotalPrice } = useFormContext();

  const { errors, setErrors, handleBlur, validationsForm } = useValidations();
  const [errorsBeneficiary, setErrorsBeneficiary] = useState([]);

  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState(null);

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // Se suma 1 porque los meses se indexan desde 0

  // Filtrar los meses para excluir los anteriores al mes actual si el año seleccionado es el año actual
  const filteredMonths = months.filter((month, index) => {
    if (formData.year === currentYear.toString()) {
      return index + 1 >= currentMonth; // Se suma 1 para ajustar el índice del mes
    }
    return true; // Si no es el año actual, se incluyen todos los meses
  });
  const citySearch = (e) => {
    handleInputChange(e);
    const zip = e.target.value;
    //if (zip.length > 4) {
    //const city = locations.find((location) => location.zip === zip);
    //setFormData({ ...formData, city: city });
    //}
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // Si el campo pertenece a 'direction', actualiza solo ese campo dentro de 'direction'
    switch (formData.securance) {
      case securanceMilenium:
        switch (true) {
          case name === "selectedOption" && value !== "yes" && formDataAddPerson.length >= 1:
            setTotalPrice((prevTotalPrice) => prevTotalPrice - precioPorBeneficiario);
            break;
          case name === "selectedOption" && value === "yes" && formDataAddPerson.length >= 1:
            setTotalPrice((prevTotalPrice) => prevTotalPrice + precioPorBeneficiario);
            break;
          default:
            break;
        }
        break;
      case securancePremium:
        switch (true) {
          case name === "selectedOption" && value !== "yes" && formDataAddPerson.length === 1:
            setTotalPrice(12.65);
            break;
          case name === "selectedOption" && value !== "yes" && formDataAddPerson.length === 2:
            setTotalPrice(25.3);
            break;
          case name === "selectedOption" && value === "yes" && formDataAddPerson.length === 1:
            setTotalPrice(25.3);
            break;
          case name === "selectedOption" &&
            value !== "yes" &&
            formDataAddPerson.length >= 3 &&
            formDataAddPerson.length <= 6:
            setTotalPrice(36.95);
            break;
          case name === "selectedOption" &&
            value === "yes" &&
            formDataAddPerson.length >= 2 &&
            formDataAddPerson.length <= 5:
            setTotalPrice(36.95);
            break;
          case name === "selectedOption" && value !== "yes" && formDataAddPerson.length >= 7:
            setTotalPrice((prevTotalPrice) => prevTotalPrice - pricePremiumExtra);
            break;
          case name === "selectedOption" && value === "yes" && formDataAddPerson.length >= 6:
            setTotalPrice((prevTotalPrice) => prevTotalPrice + pricePremiumExtra);
            break;
          default:
            break;
        }
        break;
      default:
        break;
    }

    if (name === "legal2") {
      if (formData.legal2) {
        setFormData({
          ...formData,
          legal2: false,
          subLegal1: "",
          subLegal2: "",
          subLegal3: "",
        });
      } else {
        setFormData({
          ...formData,
          legal2: true,
          subLegal1: "yes",
          subLegal2: "yes",
          subLegal3: "yes",
        });
      }
    } else {
      // Si no es un campo dentro de 'direction', actualiza el estado como lo haces actualmente
      setFormData({ ...formData, [name]: value });
    }
    const error = validationsForm(name, value);
    setErrors({ ...errors, [name]: error });
  };

  const [formDataAddPerson, setFormDataAddPerson] = useState([]); // Estado para mantener los datos de los formularios
  const [showForm, setShowForm] = useState(false); // Estado para controlar la visualización de los formularios
  // const [totalPrice, setTotalPrice] = useState(0); // Estado para almacenar el precio total

  const precioPorBeneficiario = formData.price; // Definir el precio por beneficiario

  const getBeneficiaryIndex = (index) => {
    return formData.selectedOption === "yes" ? index + 2 : index + 1;
  };
  const [nextId, setNextId] = useState(0);
  const handleAddPerson = () => {
    // Verificar si ya hay 12 beneficiarios
    if (formDataAddPerson.length < 12) {
      setShowForm(true); // Mostrar el formulario cuando se hace clic en "Add Person"
      setFormDataAddPerson([
        ...formDataAddPerson,
        {
          id: nextId, // Convertir el ID a cadena
          [`firstName${nextId}`]: "", // Usar el ID como parte del nombre del campo
          [`lastName${nextId}`]: "",
          [`birthDate${nextId}`]: "",
          [`dni${nextId}`]: "",
          [`relationship${nextId}`]: "",
          [`gender${nextId}`]: "",
        },
      ]);

      setTotalPrice(() => totalPrice + precioPorBeneficiario);

      if (formData.securance === securanceMilenium) {
        if (formData.selectedOption !== "yes" && formDataAddPerson.length === 0) {
          setTotalPrice(precioPorBeneficiario);
        }
      }
      if (formData.securance === securancePremium) {
        if (formData.selectedOption !== "yes" && formDataAddPerson.length === 0) {
          setTotalPrice(precioPorBeneficiario);
        } else if (
          formData.selectedOption === "yes" &&
          formDataAddPerson.length >= 1 &&
          formDataAddPerson.length <= 4
        ) {
          setTotalPrice(36.95);
        } else if (
          formData.selectedOption !== "yes" &&
          formDataAddPerson.length >= 2 &&
          formDataAddPerson.length <= 5
        ) {
          setTotalPrice(36.95);
        } else if (formData.selectedOption !== "yes" && formDataAddPerson.length > 5) {
          setTotalPrice(() => totalPrice + pricePremiumExtra);
        } else if (formData.selectedOption === "yes" && formDataAddPerson.length > 4) {
          setTotalPrice(() => totalPrice + pricePremiumExtra);
        }
      }

      setNextId(nextId + 1);
    } else {
      console.log("Ya has alcanzado el límite de beneficiarios.");
    }
  };

  const handleInputChangeAddPerson = (index, event) => {
    const { name, value } = event.target;
    const newFormDataAddPerson = [...formDataAddPerson];
    newFormDataAddPerson[index][name] = value;
    setFormDataAddPerson(newFormDataAddPerson);
    setErrorsBeneficiary(validationsFormBeneficiary(newFormDataAddPerson));
  };
  const handleRemovePerson = (id) => {
    const newFormDataAddPerson = formDataAddPerson.filter((person) => person.id !== id);
    setFormDataAddPerson(newFormDataAddPerson);

    setTotalPrice((prevTotal) => prevTotal - precioPorBeneficiario);

    if (formData.securance === securancePremium) {
      if (formData.selectedOption !== "yes" && newFormDataAddPerson.length >= 0 && newFormDataAddPerson.length <= 1) {
        setTotalPrice(12.65);
      } else if (
        formData.selectedOption !== "yes" &&
        newFormDataAddPerson.length >= 1 &&
        newFormDataAddPerson.length <= 2
      ) {
        setTotalPrice(25.3);
      } else if (
        formData.selectedOption === "yes" &&
        newFormDataAddPerson.length >= 0 &&
        newFormDataAddPerson.length <= 1
      ) {
        setTotalPrice(25.3);
      } else if (
        formData.selectedOption === "yes" &&
        newFormDataAddPerson.length >= 1 &&
        newFormDataAddPerson.length <= 5
      ) {
        setTotalPrice(36.95); // Cambiar el precio total a 36.95 si hay entre 3 y 6 beneficiarios y se ha seleccionado el seguro premium
      } else if (
        formData.selectedOption !== "yes" &&
        newFormDataAddPerson.length >= 2 &&
        newFormDataAddPerson.length <= 5
      ) {
        setTotalPrice(36.95); // Cambiar el precio total a 36.95 si hay entre 3 y 6 beneficiarios y se ha seleccionado el seguro premium
      } else if (formData.selectedOption !== "yes" && newFormDataAddPerson.length > 5) {
        setTotalPrice(() => totalPrice - pricePremiumExtra);
      } else if (formData.selectedOption === "yes" && newFormDataAddPerson.length > 4) {
        setTotalPrice(() => totalPrice - pricePremiumExtra);
      }
    }

    if (newFormDataAddPerson.length === 0) {
      setTotalPrice(precioPorBeneficiario);
    }
  };

  const handleNext = () => {
    setFormData({ ...formData, step: formData.step + 1 });
    window.scrollTo({ top: 0, behaviour: "smooth" });
  };
  const handleBack = () => {
    setFormData({ ...formData, step: formData.step - 1 });
    window.scrollTo({ top: 0, behaviour: "smooth" });
  };

  const validationsFormBeneficiary = (formDataAddPerson) => {
    let errorsBeneficiary = {};
    let regexName = /^[A-Za-zÑñÁáÉéÍíÓóÚúÜüÇç\s]+$/;
    let regexDni =
      /^[XYZxyz]?[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKEtrwagmyfpdxbnjzsqvhlcke]|[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKEtrwagmyfpdxbnjzsqvhlcke]$/i;
    // Aquí realizas las validaciones para cada formulario de beneficiario adicional
    formDataAddPerson.forEach((person) => {
      const errors = {};
      if (!person[`firstName${person.id}`]) {
        errors[`firstName${person.id}`] = "El 'Nombre' es necesario";
      } else if (!regexName.test(person[`firstName${person.id}`].trim())) {
        errors[`firstName${person.id}`] = "Introduce un 'Nombre' válido";
      }
      if (!person[`lastName${person.id}`]) {
        errors[`lastName${person.id}`] = "El 'Apellido' es necesario";
      } else if (!regexName.test(person[`lastName${person.id}`].trim())) {
        errors[`lastName${person.id}`] = "Introduce un 'Apellido' válido";
      }
      if (!person[`birthDate${person.id}`]) {
        errors[`birthDate${person.id}`] = "La 'Fecha de nacimiento' es necesaria";
      } else {
        const birthDate = new Date(person[`birthDate${person.id}`]);
        const fourteenYearsAgo = new Date();
        fourteenYearsAgo.setFullYear(fourteenYearsAgo.getFullYear() - 14);

        if (birthDate < fourteenYearsAgo) {
          // Si es menor de 14 años, el campo DNI no es obligatorio
          if (!person[`dni${person.id}`]) {
            errors[`dni${person.id}`] = "El 'DNI' es necesario";
          } else if (!regexDni.test(person[`dni${person.id}`].trim())) {
            errors[`dni${person.id}`] = "Introduce un 'DNI' válido";
          }
        }
      }
      if (!person[`relationship${person.id}`]) {
        errors[`relationship${person.id}`] = "El 'Parentesco con el tomador' es necesario";
      }
      if (!person[`gender${person.id}`]) {
        errors[`gender${person.id}`] = "Elige un sexo";
      }
      // Aquí puedes agregar más validaciones según tus necesidades
      errorsBeneficiary[person.id] = errors;
    });
    return errorsBeneficiary;
  };
  const handleBlurBeneficiary = (index, e) => {
    handleInputChangeAddPerson(index, e);
    setErrorsBeneficiary(validationsFormBeneficiary(formDataAddPerson));
  };

  const [confirmed, setConfirmed] = useState(false);
  const handleConfirm = () => {
    setErrors(validationsForm(formData));
    setErrorsBeneficiary(validationsFormBeneficiary(formDataAddPerson));
    setConfirmed(true);
  };
  const allFormData = { ...formData, formDataAddPerson };
  const formDataJSONpe = JSON.stringify(allFormData);
  const handleSubmit = () => {
    const allFormData = { ...formData, formDataAddPerson };
    const url = "https://sanitasempleados.flyingpigs.es/backend/azure.php";

    const formDataJSONpe = JSON.stringify(allFormData);

    fetch(url, {
      method: "POST", // Método de la solicitud
      headers: {
        "Content-Type": "application/json", // Tipo de contenido
      },
      body: JSON.stringify(formData), // Cuerpo de la solicitud
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error("Error en la solicitud POST: " + response.statusText);
        }
        return response.json(); // Convertir la respuesta a JSON
      })
      .then((data) => {
        console.log("Respuesta del servidor:", data);
        setFormData({ ...formData, step: formData.step + 1 });
        handleNext();
      })
      .catch((error) => {
        console.error("Error:", error);
      });

    const formDataJSON = JSON.stringify(formData);
  };

  return (
    <>
      <div className="main">
        {formData.step === 1 && <Step1 onNext={handleNext} />}
        {formData.step === 2 && (
          <Step2
            onNext={handleNext}
            filteredMonths={filteredMonths}
            handleInputChange={handleInputChange}
            handleBack={handleBack}
            errors={errors}
            handleBlur={handleBlur}
            setErrors={setErrors}
            validationsForm={validationsForm}
          />
        )}
        {formData.step === 3 && (
          <Step3
            onNext={handleNext}
            errors={errors}
            errorsBeneficiary={errorsBeneficiary}
            handleInputChange={handleInputChange}
            formDataAddPerson={formDataAddPerson}
            setFormDataAddPerson={setFormDataAddPerson}
            showForm={showForm}
            setShowForm={setShowForm}
            getBeneficiaryIndex={getBeneficiaryIndex}
            handleAddPerson={handleAddPerson}
            handleInputChangeAddPerson={handleInputChangeAddPerson}
            handleRemovePerson={handleRemovePerson}
            handleBack={handleBack}
            handleBlur={handleBlur}
            handleBlurBeneficiary={handleBlurBeneficiary}
            citySearch={citySearch}
          />
        )}
        {formData.step === 4 && (
          <Step4
            onNext={handleNext}
            errors={errors}
            handleBlur={handleBlur}
            handleInputChange={handleInputChange}
            handleBack={handleBack}
          />
        )}
        {formData.step === 5 && (
          <Step5
            onNext={handleNext}
            filteredMonths={filteredMonths}
            handleInputChange={handleInputChange}
            formDataAddPerson={formDataAddPerson}
            setFormDataAddPerson={setFormDataAddPerson}
            showForm={showForm}
            setShowForm={setShowForm}
            getBeneficiaryIndex={getBeneficiaryIndex}
            handleAddPerson={handleAddPerson}
            handleInputChangeAddPerson={handleInputChangeAddPerson}
            handleRemovePerson={handleRemovePerson}
            handleBack={handleBack}
            handleSubmit={handleSubmit}
            errors={errors}
            handleBlur={handleBlur}
            handleConfirm={handleConfirm}
            confirmed={confirmed}
            setConfirmed={setConfirmed}
          />
        )}

        {formData.step === 6 && <Step6 className="step6-page" />}
      </div>
      <Footer>salud</Footer>
    </>
  );
};
