export const Error = () => {
  //let history = useHistory();

  return (
    <main>
      <div className="container">
        <span className="h1">Error!</span>
      </div>
    </main>
  );
};
